.blue-color-main {
  color: #1e2142;
  font-weight: 500;
}

.orange-color-main {
  color: #f2915d;
  font-weight: 500;
}

.bg-white-dm{
  background-color: white !important;
  color: #1e2142 !important;
}

.primary-orange-bg{
  background-color: #f2915d;
  color: white;
}

.bg-none{
  background: none;
}

.py-3r{
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.fz-20px {
  font-size: 20px;
}
.fz-18px {
  font-size: 18px;
}

.fw-bold {
  font-weight: bold;
}

.p-absolute {
  position: absolute;
}
.p-relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.text-sm {
  font-size: 20px;
}

.text-lg {
  font-size: 36px;
}

.text-center {
  text-align: center;
}

.flex-column {
  flex-direction: column;
}

.align-itm-center {
  align-items: center;
}

.justify-cont-center {
  justify-content: center;
}
.justify-cont-left {
  justify-content: left;
}

.justify-cont-end {
  justify-content: end;
}
.justify-cont-evenly {
  justify-content: space-evenly;
}

.my-1r {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2r {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-4r{
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mb-05r {
  margin-bottom: 0.5rem;
}
.mb-2r {
  margin-bottom: 2rem;
}
.mt-3r {
  margin-top: 3rem;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.p-105r{
  padding: 1.5rem;
}

.py-2r {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-2r{
  padding-bottom: 2rem;
}

.pr-05r{
  padding-right: 0.5rem;
}

.p-4r{
  padding: 4rem;
}

.justify-cont-around {
  justify-content: space-around;
}

.justify-cont-between {
  justify-content: space-between;
}

.mb-1r {
  margin-bottom: 1rem;
}
.mt-2r {
  margin-top: 2rem;
}

.w-100 {
  width: 100vw;
}
.w-80 {
  width: 80vw;
}
.w-70 {
  width: 70vw;
}
.w-50p {
  width: 50%;
}
.w-40p {
  width: 40%;
}
.w-30p {
  width: 30%;
}

.w-60p {
  width: 60%;
}

.w-70p {
  width: 70%;
}

.w-80p {
  width: 80%;
}

.h-100p {
  height: 100%;
}

.w-100p {
  width: 100%;
}

.h-auto {
  height: auto;
}

.mr-3r {
  margin-right: 3rem;
}
.mr-2r {
  margin-right: 2rem;
}

.mr-05r{
  margin-right: 0.5rem;
}

.text-white{
  color: white;
}

.my-05r{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mt-05r{
  margin-top: 0.5rem;
}

.ml-2r{
  margin-left: 2rem;
}

.w-auto{
  width: auto;
}

.w-max-cont{
  width: max-content;
}

.mt-5r{
  margin-top: 5rem;
}

.color-white-dm{
  color: #FFF !important;
}

.bg-blue-dm{
  background-color: #1e2142 !important;
}

.font-eb{
  font-family: "Eagle Book";
}
.fw-500{
  font-weight: 500;
}

.flex-wrap{
  flex-wrap: wrap;
}

.mx-1r{
  margin-left: 1rem;
  margin-right: 1rem;
}

.mh-380{
  min-height: 380px;
}

.color-black{
  color: black;
}

.w-min{
  width: min-content;
}

.bg-white{
  background: white;
}

.w-25p{
  width: 25%;
}

.mt-6r{
  margin-top: 6rem;
}

.mt-neg4r{
  margin-top: -4rem;
}

.mt-4r{
  margin-top: 4rem;
}

.mr-7r{
  margin-right: 7rem;
}
.ml-5r{
  margin-left: 5rem;
}
.ml-3r{
  margin-left: 3rem;
}

.fz-28px{
  font-size: 28px;
}

.mx-2r{
  margin-left: 2rem;
  margin-right: 2rem;
}

.pt-2r{
  padding-top: 2rem;
}

.pt-6r{
  padding-top: 6rem !important;
}

.w-20p{
  width: 20%;
}

.mt-5r-imp{
  margin-top: 5rem;
}
.lh-32{
  line-height: 32px;
}

.mt-1r{
  margin-top: 1rem;
}

.br-15{
  border-radius: 15px;
}

.mr-1r{
  margin-right: 1rem;
}

.pt-6re{
  padding-top: 6rem;
}
.pt-4r{
  padding-top: 4rem;
}

.pb-2r{
  padding-bottom: 2rem;
}

.flex-wrap{
  flex-wrap: wrap;
}