@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap");

@font-face {
  font-family: "Eagle Book";
  src: url("../static/fonts/EagleBook.otf") format("opentype");
}
@font-face {
  font-family: "Eagle Bold";
  src: url("../static/fonts/EagleBold.otf") format("opentype");
}

@font-face {
  font-family: "Eagle Light";
  src: url("../static/fonts/EagleLight.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.App {
  overflow-x: hidden;
}

.navbar {
  border-bottom: 2px solid white;
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: "Eagle Book";
  /* width: 100vw; */
  display: flex;
  justify-content: space-around;
  padding: 0.7rem;
}

.navbar ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar ul li {
  padding: 1rem 2rem;
}

.nav-link-container {
  display: flex;
}

.section-with-contact-us {
  position: relative;
  background: url("../static/main-home-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  width: 100vw;
}

.contact-query {
  padding: 0.7rem 3rem;
  border-radius: 6px 6px 0px 0px;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 26px; /* 92.857% */
}

.contact-box {
  border-radius: 4px;
  box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.25);
  bottom: -10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 25%;
  z-index: 100;
  font-family: "Eagle Book";
}

.contact-org-box {
  border-radius: 0px 10px 10px 10px;
}

.display-text {
  font-family: "Eagle Book";
  font-size: 20px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: normal;
}

.info-text {
  margin: 1rem 0;
  color: #1e2142;
  font-size: 20px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: normal;
  font-family: "Eagle Light" !important;
}

.services {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card {
  padding: 2rem;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  width: 70vw;
  /* justify-content: space-evenly; */
}
.service-card-dark {
  padding: 2rem;
  background: #1e2142;
  box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.25);
  width: 70vw;
  /* justify-content: space-evenly; */
}

.img-w55 {
  max-width: 240px;
  width: 47%;
}

.services-list-item {
  font-family: "Eagle Light" !important;
  color: #1e2142;
  font-size: 24px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 159.523%;
  list-style: none;
  display: flex;
}

.services-list-item:before {
  font-family: "Eagle Light" !important;
  content: "•";
  font-size: 120%;
  padding-right: 10px;
}

.services-text-container {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.light-text {
  color: #1e2142;
  font-family: "Eagle Light" !important;
  font-size: 24px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 47px; /* 195.833% */
}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

.about-us-img {
  border-radius: 0px 326.5px 0px 123px;
  height: auto;
  width: 30%;
  max-width: 650px;
}

.left-about-img {
  height: auto;
  width: 200px;
}

.about-us {
  font-family: "Eagle Book";
}

.about-heading {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.get_estimate_button {
  width: 50%;
  font-family: "Eagle Light";
  color: #fff;
  background: none;
  padding: 1rem 0rem;
  border-radius: 12px;
  border: none;
  font-weight: normal;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.1s all ease-in-out;
}

.get_estimate_button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.bg-blue-cw {
  color: white;
  background: #1e2142;
}

.services {
  font-family: "Eagle Book";
}

.box-carousel {
  display: flex;
  transition: transform 0.5s;
  justify-content: center;
}

.box-carousel.prev {
  transform: translateX(33.33%);
}

.box-carousel.next {
  transform: translateX(-33.33%);
}

.box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40%;
  transition: transform 0.5s, z-index 0.5s;
}

.box-text {
  font-family: "Eagle Light";
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  font-size: 43px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: normal;
}

.active-box {
  z-index: 10;
  border-radius: 8px;
  margin-top: 0rem;
  transform: scale(1.1);
}

.box:first-child {
  transform: translateX(30%) scale(0.9);
  z-index: 1;
}

.box:last-child {
  transform: translateX(-30%) scale(0.9);
  z-index: 1;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.box img {
  height: 440px;
  width: 600px;
}

.box-carousel-container {
  position: relative;
  margin: 3rem 0;
}

.prev-btn:focus,
.next-btn:focus {
  outline: none;
}

.second {
  filter: brightness(1) !important;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: black;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}
.theme {
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.theme__fill,
.theme__icon_dark,
.theme__icon {
  transition: 0.3s;
}

.theme__fill {
  background-color: var(--bg);
  display: block;
  mix-blend-mode: difference;
  position: fixed;
  inset: 0;
  height: 100%;
  transform: translateX(-100%);
}

.theme__icon,
.theme__icon_dark,
.theme__toggle {
  z-index: 1;
}

.theme__icon,
.theme__icon_dark,
.theme__icon-part {
  position: absolute;
}

.theme__icon {
  display: block;
  top: 0.5em;
  left: 0.5em;
  width: 1.5em;
  height: 1.5em;
}
.theme__icon_dark {
  display: block;
  top: 0.5em;
  right: 0em;
  width: 1.5em;
  height: 1.5em;
}

.theme__icon-part {
  border-radius: 50%;
  box-shadow: 0.4em -0.4em 0 0.5em hsl(0, 0%, 100%) inset;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  width: 1em;
  height: 1em;
  transition: box-shadow var(--transDur) ease-in-out,
    opacity var(--transDur) ease-in-out, transform var(--transDur) ease-in-out;
  transform: scale(0.5);
}

.theme__icon-part ~ .theme__icon-part {
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.05em;
  top: 50%;
  left: calc(50% - 0.05em);
  transform: rotate(0deg) translateY(0.5em);
  transform-origin: 50% 0;
  width: 0.1em;
  height: 0.2em;
}

.theme__icon-part:nth-child(3) {
  transform: rotate(45deg) translateY(0.45em);
}

.theme__icon-part:nth-child(4) {
  transform: rotate(90deg) translateY(0.45em);
}

.theme__icon-part:nth-child(5) {
  transform: rotate(135deg) translateY(0.45em);
}

.theme__icon-part:nth-child(6) {
  transform: rotate(180deg) translateY(0.45em);
}

.theme__icon-part:nth-child(7) {
  transform: rotate(225deg) translateY(0.45em);
}

.theme__icon-part:nth-child(8) {
  transform: rotate(270deg) translateY(0.5em);
}

.theme__icon-part:nth-child(9) {
  transform: rotate(315deg) translateY(0.5em);
}

.theme__label,
.theme__toggle,
.theme__toggle-wrap {
  position: relative;
}

.theme__toggle,
.theme__toggle:before {
  display: block;
}

.theme__toggle {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 0.125em var(--primaryT);
  padding: 0.25em;
  width: 6em;
  height: 3em;
  -webkit-appearance: none;
  appearance: none;
  transition: background-color var(--transDur) ease-in-out,
    transform var(--transDur) ease-in-out;
}

.theme__toggle:before {
  background-color: #f2915d;
  border-radius: 50%;
  content: "";
  width: 2.5em;
  height: 2.5em;
  transition: 0.3s;
}

.theme__toggle:focus {
  box-shadow: 0 0 0 0.125em var(--primary);
  outline: transparent;
}

/* Checked */
.theme__toggle:checked {
  background-color: #fff;
}

.theme__toggle:checked:before,
.theme__toggle:checked ~ .theme__icon {
  transform: translateX(3em);
}

.theme__toggle:checked:before {
  background-color: #1e2142;
}

.theme__toggle:checked ~ .theme__fill {
  transform: translateX(0);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(1) {
  box-shadow: 0.2em -0.2em 0 0.2em hsl(0, 0%, 100%) inset;
  transform: scale(1);
  top: 0.2em;
  left: -0.2em;
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part ~ .theme__icon-part {
  opacity: 0;
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(2) {
  transform: rotate(45deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(3) {
  transform: rotate(90deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(4) {
  transform: rotate(135deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(5) {
  transform: rotate(180deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(6) {
  transform: rotate(225deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(7) {
  transform: rotate(270deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(8) {
  transform: rotate(315deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(9) {
  transform: rotate(360deg) translateY(0.8em);
}

.theme__toggle-wrap {
  display: flex;
  background-color: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: 0 0.75em;
}

@supports selector(:focus-visible) {
  .theme__toggle:focus {
    box-shadow: 0 0 0 0.125em var(--primaryT);
  }

  .theme__toggle:focus-visible {
    box-shadow: 0 0 0 0.125em var(--primary);
  }
}

.client-section {
  width: 97vw;
  margin: auto;
  height: 500px;
  background: #1e2142;
}

.horizontal-line {
  border-top: 2px solid #fff; /* Adjust the color, thickness, and style as needed */
  width: 100px; /* Add some spacing between the line and the text */
  margin: auto;
  margin-right: 1rem;
}

.client-bg {
  position: relative;
  height: 100%;
  background: url("../static/client-left.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: end;
  align-items: center;
}

.client-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2915d; /* Adjust the color and opacity */
  opacity: 0.8;
  z-index: 0; /* Ensure the overlay is above other content */
}

.client-content {
  position: relative;
  z-index: 10;
}

.client-head {
  font-family: "Eagle Book";
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px; /* 128% */
  letter-spacing: 0.5px;
}

.client-text-sm {
  font-family: "Eagle Light";
  letter-spacing: 2px;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
}

.client-read-btn {
  font-family: "Eagle Light";
  width: 30%;
  padding: 1rem;
  background-color: #1e2142;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: normal;
  outline: none;
  border: none;
}

.client-testimonial {
  display: flex;
  margin: 0 1rem;
  padding: 0 1rem;
  justify-content: center;
  align-items: center;
}

.testimonial-pfp {
  border-radius: 50%;
  border: 3px solid #f2915d;
}

.testimonial-name-car {
  color: #fff;
  font-family: "Eagle Book";
  font-size: 24px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 30px; /* 125% */
}

.testimonial-text-car {
  color: #bcbcbc;
  font-family: "Eagle Light";
  font-size: 16px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 30px; /* 187.5% */
  letter-spacing: 1.3px;
}

.footer {
  padding: 2rem;
}

#mobile_footer {
  padding: 0 2rem !important;
  padding-bottom: 2rem !important;
}

.footer-text {
  font-family: "Eagle Book";
  color: #bbbdc3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 168.75% */
}

.font-el {
  font-family: "Eagle Light" !important;
}

.text-ccc {
  font-size: 24px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 47px; /* 195.833% */
  font-family: "Eagle Light" !important;
  color: #ccc;
}

.insta-post {
  padding: 1rem;
  width: 20%;
  height: auto;
}

.line {
  width: 100px;
  border-top: 5px solid black;
}

.alice-carousel__dots {
  padding: 0 1rem !important;
  text-align: initial !important;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  width: auto !important;
}

.alice-carousel__prev-btn {
  margin-left: 1.2rem;
  text-align: initial;
}

.light_mode_text {
  color: black !important;
}

/* header */

.header {
  border-bottom: 3px solid white;
  background-color: #1e2142;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100vw;
  z-index: 151;
}

.logo img {
  height: 40px;
  width: auto;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header li {
  text-align: center;
}

.header li a {
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
  color: #1e2142;
  font-family: "Eagle Book";
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}

.header .logo {
  display: block;
  float: left;
  /* font-size: 2em; */
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  background-color: #1e2142;
}

/* menu icon */

.menu-icon {
  position: absolute !important;
  right: 0;
  filter: invert(1);
  zoom: 1.3;
}

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  /* float: right; */
  padding: 24px 16px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

/* .header .menu-btn:checked ~ .menu {
  max-height: 240px;
} */
/* 
.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
} */

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

@media (min-width: 48em) {
  .header {
    display: none;
  }
}

.top-bg {
  content: "";
  position: relative;
  /* top: ; */
  left: 0;
  width: 100vw;
  height: 150px;
  background-image: url("../static/top-bg.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  z-index: 2;
  /* opacity: 0.7; */
}

.small {
  position: absolute;
  top: 45%;
  left: 15%;
  color: #bbbdc3;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.heading {
  color: #303442;
  text-align: center;
  font-family: "Poppins";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 46px; */
  margin-top: 3rem;
}

.options {
  color: #303442;
  font-family: "Eagle Book";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 2rem 2rem;
}

.textBox {
  z-index: 10;
  background: #fff;
  width: 80%;
  padding: 1rem;
  transform: translateY(-30%);
}

.text-head {
  color: #303442;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}

.other-text {
  color: #7b7b7b;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.read-more-btn {
  color: #303442;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.orange-btn {
  padding: 1rem;
  outline: none;
  border: none;
  color: white;
  width: 15%;
  background-color: #f47629;
  border-radius: 4px;
  margin: 2rem auto;
  font-weight: bold;
  font-family: "Poppins";
}

.dots {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}

.information-box {
  background-color: white;
  margin-top: 2rem;
  margin-left: 2rem;
  transform: translateX(10%);
  padding: 2rem;
}

.why-us-item {
  color: #303442;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
  margin: 0 0.5rem;
}

.why-us-head {
  font-size: 36px;
  line-height: 45px;
  font-weight: bold;
  margin: 1rem 0;
}

.why-us-sm {
  color: #f47629;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 26px; /* 162.5% */
  font-weight: bold;
}

.text-btn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.nav-link-container ul a {
  text-decoration: none;
}

.map {
  border: 0px;
  width: 100vw;
  height: 350px;
}

.details-container {
  width: 80%;
  margin: auto;
  padding: 0 3rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  background: white;
  transform: translateY(-40%);
}

.details-box {
  color: #7b7b7b;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
}

.form {
  margin-top: 2rem;
}

.form input {
  width: 95%;
  border-radius: 4px;
  border: 2px solid #f4f4f4;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 2px 4px 0px rgba(171, 171, 171, 0.13);
  font-family: "Roboto";
  color: #6c757d;
  font-size: 16px;
}

.first-input {
  padding: 1rem;
}

.other-inputs {
  padding: 1rem;
}

.form input:focus {
  outline: none;
}

.form textarea {
  width: 95%;
  resize: none;
  outline: none;
  border: none;
  border-radius: 4px;
  border: 2px solid #f4f4f4;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 2px 4px 0px rgba(171, 171, 171, 0.13);
  padding: 1rem;
  margin: 1rem 0;
  font-family: "Roboto";
  color: #6c757d;
  font-size: 16px;
}

.orng-btn {
  outline: none;
  border: none;
  font-family: "Poppins";
  /* border-radius: 4px; */
  padding: 1rem 2rem;
  background: #f2915d;
  color: white;
  font-size: 14px;
  margin: 1rem 0;
  width: -moz-fit-content;
  width: fit-content;
}

.form {
  margin-bottom: 6rem;
}

.orng-btn:hover,
.orange-btn:hover,
.read-more-btn:hover,
.client-read-btn:hover {
  cursor: pointer;
  outline: none;
}

.menu a {
  text-decoration: none;
  font-family: "Eagle Book";
  padding: 1rem;
  color: #fff;
}

.corner-img {
  right: 0;
  bottom: 0;
  transform: translate3d(15%, 15%, 0px);
}

.p-3-0 {
  padding: 4rem 0;
}

.orange-circle {
  height: 70px;
  width: 70px;
  background: #f47629;
  border-radius: 50%;
  margin-right: 1rem;
}

.about-no {
  color: #303442;
  font-family: "Poppins";
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 76.19% */
}

.img-container {
  z-index: -10;
}

.about-us-sec2 {
  margin: 4rem 0;
  overflow: hidden;
}

.about-info-box {
  transform: none;
  height: 100%;
  margin: 0;
  margin-top: 4rem;
}

.need-help {
  position: relative;
  background: url("../static/about-us-help.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 680px;
  width: 100vw;
}

.vertical-orange {
  border: 3px solid #f47629;
  margin-right: 2rem;
}

.help-box-other-text {
  padding: 2rem 0;
  width: 90%;
}

.btn-black,
.btn-orange {
  border-radius: 4px;
  width: 30%;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  font-family: "Poppins";
  color: white;
  cursor: pointer;
}

.btn-black {
  background-color: #303442;
}

.btn-orange {
  background-color: #f47629;
}

.help-box {
  transform: translateX(35%) translateY(20%);
}

/* AboutUsCarousel.css */

/* Style for each testimonial card */
.testimonial-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  margin: 10px;
}

/* Style for testimonial avatar (photo) */
.testimonial-avatar {
  width: auto;
  height: 350px;
  object-fit: cover;
}

/* Style for testimonial name */
.testimonial-name {
  font-weight: bold;
  font-size: 1.2rem;
  color: #303442;
  font-family: "Poppins";
  margin-top: 10px;
}

/* Style for testimonial position */
.testimonial-position {
  color: #7b7b7b;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.big-no {
  position: relative;
  z-index: -1;
  color: #e0e1e4;
  font-family: "Poppins";
  font-size: 170px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px; /* 27.059% */
}

.box-with-no {
  width: 30%;
}

.big-no-text-container {
  padding: 2rem;
  margin-right: 1rem;
  transform: translate(10%, -70%);
  box-shadow: 0px 0px 20px 0px rgba(48, 52, 66, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-container {
  margin-top: 5rem;
  justify-content: space-evenly;
}

.box-with-no-right-dots {
  position: absolute;
  right: 0;
  padding: 1em;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
  transform: scale(0.7);
  /* filter: invert(0.6); */
}

.trademark {
  color: #bbbdc3;
  font-family: "Eagle Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 168.75% */
}

.mode-text {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-family: "Eagle Light";
}

.right-0-imp {
  left: unset;
  transform: translateX(0) !important;
  right: 0 !important;
}

.ask_query {
  font-family: "Eagle Book";
  font-weight: initial;
  width: 100% !important;
  padding: 1.5rem 3.5rem !important;
}

.image-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 80%; /* Adjust the width as needed */
  margin: 0 auto;
  max-height: 400px; /* Adjust the maximum height as needed */
}

.carousel-item {
  /* position: absolute; */
  width: 33.33%; /* Display 3 images at a time */
  transition: transform 0.5s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
}

.previous {
  transform: translateX(20%);
  opacity: 0.7;
  z-index: 0 !important;
  max-height: 350px;
  filter: blur(1px);
}

.current {
  transform: scale(1.1);
  opacity: 1;
  height: 400px;
  width: 490px;
  z-index: 10;
}

.next {
  filter: blur(1px);
  transform: translateX(-20%);
  opacity: 0.7;
  z-index: 0 !important;
  max-height: 350px;
}

.img_carousel_img {
  object-fit: cover;
  width: 470px;
  height: 350px;
}

.fz-35px {
  font-size: 35px;
}

.map-container {
  position: relative;
  width: 350px;
  height: 250px;
}

.dark-mode-map {
  filter: invert(100%) hue-rotate(180deg);
}

.border-rounded {
  border-radius: 8px;
}

.testimonial-text {
  color: black;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 166.667% */
}

.test-name {
  color: black;
  /* Inter/Semi Bold/16 */
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.pfp {
  height: 55px;
  width: 55px;
  border-radius: 50%;
}

.review-box {
  margin: 0 2rem;
}
.link-a {
  color: #fff;
  text-decoration: none;
}

.link-a > a {
  color: white;
  text-decoration: none;
}

.form-icon {
  /* opacity: 0.8; */
  /* height: 30px; */
  filter: invert(0);
}

.slider_arrow {
  /* filter: invert(1); */
  height: 40px;
}

.arrow_btn {
  position: absolute;
  z-index: 100;
  /* top: 50%; */
  top: -30%;
  left: -100%;
}

.arrow_btn_right {
  position: absolute;
  z-index: 100;
  /* top: 50%; */
  top: -30%;
  right: -100%;
  transform: scaleX(-1);
}

.pc-logo {
  height: 85px;
}


.dot_white {
  height: 20px;
  width: 20px;
  background: #e0e4fb;
  border-radius: 50%;
  margin: 0 1rem;
}
.dot_blue {
  height: 20px;
  width: 20px;
  background: #6e7ebc;
  border-radius: 50%;
  margin: 0 1rem;
}


.partner_2 {
  /* border: 15px solid white; */
  height: auto;
  width: 135px;
  margin: 2rem 2rem;
}

.reject-cookie{
  color: #b3b4b4 !important;
  margin-right: 0.8rem;
}

.button-arrow{
  margin-left: 1rem;
}

.partner_1{
  height: max-content;
  border-radius: 12px;
}

.link-a-head {
  color: #f2915d;
  text-decoration: none;
  cursor: pointer;
}

.link-a-head:hover {
  text-decoration: underline;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:300,500");

.form-popup-bg {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  overflow-y: auto;
  z-index: 10000;
}

.form-popup-bg.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.form-container {
  width: 100%;
  transform: translateX(60%);
  background-color: white;
  /* border-radius: 10px; */
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  bottom: 0;
  padding: 30px;
  color: #fff;
  font-family: "Eagle Book";z-index: 1000;
}

.font-poppins {
  font-family: "Poppins";
}

.close-button {
  background: none;
  color: black;
  width: 40px;
  height: 53px;
  position: absolute;
  top: 0;
  right: 0;
  /* border: solid 1px black; */
  border: none;
  font-size: 20px;
}

.font-bold {
  font-weight: bold;
}

.form-popup-bg:before {
  content: "";
  background-color: #fff;
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.form-input {
  border: 1px solid #0000004f;
  padding: 0.6rem 0.8rem;
  margin: 0.8rem 0;
  font-family: "Poppins";
  width: 90%;
  resize: none;
}

.form-heading {
  /* color: #1E2142; */

  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px; /* 140% */
  letter-spacing: 0.5px;
}

.info-giving {
  color: #616263;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}

.form-input:focus {
  outline: none;
}

.pin-title {
  position: fixed;
  top: 1.875rem;
  bottom: 1.875rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Exo", sans-serif;
  font-weight: 800;
  font-size: 800%;
  letter-spacing: 0.0625rem;
  color: #f3f3f3;
  user-select: none;
  z-index: -1;
  text-align: center;
}

/* COOKIE POPUP STYLES */

.cookie-popup {
  font-size: 0.875rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  bottom: 1.75rem;
  left: 1.25rem;
  right: 1.25rem;
  padding: 0.625rem 0.9375rem;
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.15);
  line-height: 150%;
  transition: opacity 0.5s;
  opacity: 0;
  z-index: 100;
  width: 20%;
  background: white;
  font-family: "Montserrat", sans-serif;
}

.cookie-popup.visible{
  opacity: 1;
}

.cookie-popup a {
  color: skyblue;
}
.cookie-popup a:visited {
  color: skyblue;
  text-decoration: none;
}
.cookie-popup-actions {
  flex: 1;
  text-align: right;
}
.cookie-popup-actions button {
  color: orange;
  border: none;
  background: none;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0;
  cursor: pointer;
}
.cookie-popup-actions button:hover {
  text-decoration: underline;
}

@keyframes cookie-popup-in {
  from {
    bottom: -6.25rem;
  }
  to {
    bottom: 1.25rem;
  }
}

.pp-h3{
  font-family: "Poppins";
  margin: 0.5rem 0;
}

.pp-h4{
  font-family: "Poppins";
}

.pp{
  font-family: "Poppins";
}

.pp-container{
  width: 90%;
  margin: auto;
}

.pp-left-mar{
  margin-left: 2rem;
}

.pp-container>.text{
  font-size: 18px;
  font-family: "Poppins";
} 

.light_mode_icon{
  filter: invert(1);
}

.mail_icon{
  /* height: 28px; */
  /* opacity: 0.8; */
  width: auto;
}

.contact-link{
  position: relative;
  top: -150px;
}

.current > img, .next > img, .previous > img{
  border-radius: 12px;
}

.current{
  border-radius: 12px;
}

.rated-people-img{
  height: 28px;
  margin-top: 0.5rem;
  width: auto;
}
